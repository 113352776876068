import { render, staticRenderFns } from "./BreadPage.vue?vue&type=template&id=377a4921&scoped=true"
import script from "./BreadPage.vue?vue&type=script&lang=js"
export * from "./BreadPage.vue?vue&type=script&lang=js"
import style0 from "./BreadPage.vue?vue&type=style&index=0&id=377a4921&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "377a4921",
  null
  
)

export default component.exports