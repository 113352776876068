<template>
    <bread-page>
        <div class="container">
            <div class="news">
                <div class="carousel news-item" @mouseover="autoplay = false" @mouseout="autoplay = true">
                    <el-carousel
                        :autoplay="autoplay"
                        ref="carousel"
                        width="760px"
                        height="400px"
                        arrow="never"
                        trigger="hover"
                        @change="changeCarousl"
                    >
                        <el-carousel-item v-for="(item, index) in newsListIndex" :key="index">
                            <!-- <img
                                @click="all('/newsDetail?id=' + item.id)"
                                style="width: 100%; height: 100%; cursor: pointer"
                                :src="item.img"
                                alt=""
                            /> -->
                            <el-image
                                @click="all('/newsDetail?id=' + item.id)"
                                :src="item.img"
                                fit="cover"
                                style="width: 100%; height: 100%; cursor: pointer;"
                            ></el-image>

                            <p class="swiper-itemText">{{ item.name }}</p>
                        </el-carousel-item>
                    </el-carousel>

                    <div class="indicators">
                        <span
                            v-for="(item, index) in newsListIndex"
                            @mouseover="changeIndex(index)"
                            :key="index"
                            :class="{ active: activeIndex === index }"
                            >{{ index + 1 }}</span
                        >
                    </div>
                </div>
                <div class="news-list">
                    <div class="news-tit">
                        <span>平台动态</span>
                        <el-link :underline="false" class="more" @click="all('/newsList?flag=' + '1')">
                            更多<i class="el-icon-view el-icon-arrow-right"></i>
                        </el-link>
                    </div>
                    <div class="border"></div>
                    <router-link
                        v-for="(item, index) in newsListContent"
                        :key="index"
                        :to="{ name: 'newsDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div>
                                <span class="text1">{{ item.name }}</span>
                                <p class="text3">{{ item.issuedBy }}</p>
                            </div>
                            <span class="text2">{{ item.issuedAt }}</span>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="news-right">
                <div class="news-tit">
                    <span>领域资讯</span>
                    <el-link :underline="false" class="more" @click="all('/newsList?flag=' + '2')">
                        更多<i class="el-icon-view el-icon-arrow-right"></i>
                    </el-link>
                </div>
                <div class="news-con">
                    <div
                        class="news-top"
                        v-for="(item, index) in newsIndex"
                        :key="index"
                        @click="all('/newsDetail?id=' + item.id)"
                    >
                        <img :src="item.img" alt="" />
                        <div class="title">{{ item.name }}</div>
                    </div>
                </div>
                <div class="border"></div>
                <router-link
                    v-for="(item, index) in newsContent"
                    :key="index + 6"
                    :to="{ name: 'newsDetail', query: { id: item.id } }"
                    class="news-link"
                >
                    <div class="tit1"></div>
                    <div class="text1">{{ item.name }}</div>
                </router-link>
            </div>
        </div>
    </bread-page>
</template>

<script>
import BreadPage from '../../components/page/BreadPage.vue';
export default {
    components: { BreadPage },
    data() {
        return {
            activeIndex: 0,
            autoplay: true,
            newsList: [],
            news: []
        };
    },
    computed: {
        newsListIndex() {
            return [...this.newsList].filter((item, index) => {
                return index < 4;
            });
        },
        newsListContent() {
            return [...this.newsList].filter((item, index) => {
                return index < 6;
            });
        },
        newsIndex() {
            return [...this.news].filter((item, index) => {
                return index < 4;
            });
        },
        newsContent() {
            return [...this.news].filter((item, index) => {
                return index < 6;
            });
        }
    },
    created() {
        this.$http
            .post('/policyLaw/all', { query: { category: 'NEWS', typeSettingId: 324 } }, { body: 'json' })
            .then(res => {
                this.newsList = res.content;
            });
        this.$http
            .post('/policyLaw/all', { query: { category: 'NEWS', typeSettingId: 325 } }, { body: 'json' })
            .then(res => {
                this.news = res.content;
            });
    },
    methods: {
        changeCarousl() {
            this.activeIndex = this.$refs.carousel.activeIndex;
        },
        changeIndex(index) {
            this.$refs.carousel.setActiveItem(index);
        }
    }
};
</script>

<style lang="less" scoped>
.container {
    width: 1200px;
    .flex();
    padding-bottom: 210px;
    justify-content: space-between;
}
.main {
    margin-left: 20px;
}
.swiper-itemText {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    font-size: 16px;
    padding-left: 10px;
    color: rgba(255, 255, 255, 0.6);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: rgba(0, 0, 0, 0.4);
    line-height: 44px;
}
.news-right {
    width: 380px;
    height: 991px;
    padding: 14px 20px 0;
    background: #ffffff;
    overflow: auto;
    .news-con {
        .flex();
        flex-wrap: wrap;
        justify-content: space-between;
        .news-top {
            cursor: pointer;
            img {
                width: 184px;
                height: 110px;
            }
            .title {
                width: 182px;
                height: 72px;
                font-size: 14px;
                font-weight: 400;
                overflow: hidden;
                color: #000000;
            }
            &:hover {
                .title {
                    color: @prim;
                }
            }
        }
    }
    .news-img {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        p {
            width: 114px;
            height: 40px;
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            line-height: 20px;
            margin: 12px 0 20px;
        }
    }
    .border {
        border: 1px solid @bg;
        margin: 16px 0 16px;
    }
    .news-link {
        text-decoration: none;
        display: flex;
        .tit1 {
            width: 8px;
            height: 8px;
            margin: 6px 5px 0 0;
            border-radius: 8px !important;
            background: #e3e4e6;
        }
        .text1 {
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            line-height: 24px;
            padding-bottom: 16px;
        }
        &:hover {
            .text1 {
                color: @prim;
            }
        }
    }
    .news-tit {
        .flex();
        justify-content: space-between;
        padding-bottom: 14px;
        .more {
            font-size: 13px;
            font-weight: 400;
            color: #ff8700;
            line-height: 18px;
        }
        span {
            font-size: 20px;
            font-weight: 400;
            color: #ff8700;
            line-height: 28px;
        }
    }
}
.news {
    .carousel {
        position: relative;
    }

    .el-carousel__item {
        background-color: #99a9bf;
    }

    /deep/ .el-carousel__indicators {
        display: none;
    }

    .indicators {
        position: absolute;
        right: 12px;
        bottom: 12px;
        z-index: 2;
        span {
            width: 22px;
            height: 22px;
            background: rgba(0, 0, 0, 0.46);
            text-align: center;
            font-size: 14px;
            color: #ffffff;
            line-height: 22px;
            display: inline-block;
            cursor: pointer;

            &.active {
                background-color: @danger;
            }
        }
    }
}
.news-item {
    width: calc(100%);
}
.news-list {
    width: 720px;
    height: 549px;
    background: #ffffff;
    margin-top: 20px;
    overflow: auto;
    padding: 14px 20px 22px;
    .news-con {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        .box {
            width: 616px;
        }
    }
    .news-tit {
        .flex();
        justify-content: space-between;
        .more {
            font-size: 13px;
            font-weight: 400;
            color: #ff8700;
            line-height: 18px;
        }
        span {
            font-size: 20px;
            font-weight: 400;
            color: #ff8700;
            line-height: 28px;
        }
    }
    .border {
        border: 1px solid @bg;
        margin: 14px 0 20px;
    }
    .news-link {
        text-decoration: none;
        .flex();
        line-height: 20px;
        .text1 {
            font-size: 14px;
            color: #000;
        }

        .text2 {
            color: @info;
            font-size: 13px;
        }
        .text3 {
            color: @info;
            font-size: 13px;
            width: 610px;
            padding-top: 5px;
        }

        &:hover {
            .text1 {
                color: @prim;
            }

            .text2 {
                color: fade(@prim, 60%);
            }
        }
    }

    .news-link + .news-link {
        margin-top: 25px;
    }
}
</style>
